<template>
  <div class="ContactAgreement">
    <div class="content">
      <TinyEditor v-model="html" id="tinymce"></TinyEditor>
      <div class="save">
        <a-button type="primary" @click="save">保存</a-button>
        <a-button type="danger" style="margin-left: 20px;">取消</a-button>
      </div>
    </div>
  </div>
</template>
<script>
  import TinyEditor from "../../components/TinyEditor/TinyEditor";
  export default {
    components:{TinyEditor},
    name: 'index',
    data () {
      return {
        html:'',
      }
    },

    mounted () {
      this.getContact();
    },
    methods:{
      getContact(){
        this.$axios.get(
                20012,
                { Id: this.$route.query.Id},
                res => {
                  if (res.data.code == 1) {
                    this.html = res.data.data.Items
                  } else {
                    this.$message.error(res.data.msg)
                  }

                }
        )
      },
      save(){
        this.$axios.post(
                20013,
                { Id: 8,html:this.html},
                res => {
                  if (res.data.code == 1) {
                    this.$message.success(res.data.msg)
                    this.$router.push({ path: '/ContactAgreement'})
                  } else {
                    this.$message.error(res.data.msg)
                  }

                }
        )
      }
    }
  }
</script>
<style lang="less" scoped>
.ContactAgreement {
  height: 100%;
}
.content{
  background-color: white;
  height: 100%;
}
.save{
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}
</style>
